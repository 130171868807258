import React, { useMemo } from "react";
import { PubSub } from "aws-amplify";

import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { useAuthorizer } from "../../util/authorizer";
import { useSnackbar } from "notistack";
import { resetAlarms } from "../Redux/HP";

const deleteHPErrorLogs = async ({ Serialnumber }) => {
  const apiName = "ThermonovaAPI";
  const path = "/HP/deleteErrorLogs";
  await API.post(apiName, path, {
    body: {
      Serialnumber,
      scope: "all",
    },
  });
};

export default function OverviewGwStatus() {
  const auth = useAuthorizer();
  const { enqueueSnackbar } = useSnackbar();
  const Dispatch = useDispatch();
  const {
    lastUpdate,
    vpnIP,
    updateGWState,
    HPData,
    handlerTopic,
    activeAlarms,
    resolvedAlarms,
  } = useSelector((state: any) => state.heatpump);

  const [updateGWMessages, setUpdateGWMessages] = React.useState<any>("");

  const updateGW = () => {
    PubSub.publish(handlerTopic, { updateGW: "True" });
  };

  const deleteLogs = () => {
    deleteHPErrorLogs({ Serialnumber: HPData.Serialnumber })
      ?.then(() => {
        enqueueSnackbar("Logs deleted", { variant: "success" });
        Dispatch(resetAlarms());
      })
      .catch((error) => {
        enqueueSnackbar("Error deleting logs", { variant: "error" });
      });
  };
  const hasErrors = useMemo(() => {
    if (!activeAlarms || !resolvedAlarms) return false;
    return (
      Object.keys(activeAlarms).length > 0 ||
      Object.keys(resolvedAlarms).length > 0
    );
  }, [activeAlarms, resolvedAlarms]);

  React.useEffect(() => {
    switch (updateGWState) {
      case "nothing":
        setUpdateGWMessages("");
        break;
      case "downloading":
        setUpdateGWMessages(
          <Alert severity="info">Gateway is downloading software.</Alert>
        );
        break;
      case "restarting":
        setUpdateGWMessages(
          <Alert severity="info">Gateway is restarting.</Alert>
        );
        break;
      case "done":
        setUpdateGWMessages(
          <Alert severity="success">Gateway is updated.</Alert>
        );
        break;
      case "error":
        setUpdateGWMessages(
          <Alert severity="error">Updating the gateway failed.</Alert>
        );

        break;

      default:
        setUpdateGWMessages("");
        break;
    }
  }, [updateGWState]);

  const showupdateGateway = auth.inOrgPermissions.hasHPUpdatePermissions ? (
    <Stack spacing={2}>
      <Button onClick={updateGW} variant="contained">
        Update Gateway
      </Button>
      {updateGWMessages}
    </Stack>
  ) : (
    <></>
  );

  const showDeleteLogsButton =
    auth.isAdministrator && hasErrors ? (
      <Stack spacing={2} sx={{ marginTop: 2 }}>
        <Button onClick={deleteLogs} variant="contained" color="warning">
          Delete Logs
        </Button>
        {updateGWMessages}
      </Stack>
    ) : (
      <></>
    );

  const renderSoftwareVersionLines = () => {
    switch (HPData.Model) {
      case "Nova 110":
        return (
          <React.Fragment>
            {showVersionLine(
              "Software Master",
              HPData.Controller["100"]["SoftwareModel"] +
                "." +
                HPData.Controller["100"]["SoftwareVersion"]
            )}
            {showVersionLine(
              "Software Slave 1",
              HPData.Controller["1"]["SoftwareModel"] +
                "." +
                HPData.Controller["1"]["SoftwareVersion"]
            )}
          </React.Fragment>
        );
      case "Nova 220":
        return (
          <React.Fragment>
            {showVersionLine(
              "Software Master",
              HPData.Controller["100"]["SoftwareModel"] +
                "." +
                HPData.Controller["100"]["SoftwareVersion"]
            )}
            {showVersionLine(
              "Software Slave 1",
              HPData.Controller["1"]["SoftwareModel"] +
                "." +
                HPData.Controller["1"]["SoftwareVersion"]
            )}
          </React.Fragment>
        );

      case "Nova 330":
        return (
          <React.Fragment>
            {showVersionLine(
              "Software Master",
              HPData.Controller["100"]["SoftwareModel"] +
                "." +
                HPData.Controller["100"]["SoftwareVersion"]
            )}
            {showVersionLine(
              "Software Slave 1",
              HPData.Controller["1"]["SoftwareModel"] +
                "." +
                HPData.Controller["1"]["SoftwareVersion"]
            )}
            {showVersionLine(
              "Software Slave 2",
              HPData.Controller["2"]["SoftwareModel"] +
                "." +
                HPData.Controller["2"]["SoftwareVersion"]
            )}
          </React.Fragment>
        );

      case "Nova 440":
        return (
          <React.Fragment>
            {showVersionLine(
              "Software Master",
              HPData.Controller["100"]["SoftwareModel"] +
                "." +
                HPData.Controller["100"]["SoftwareVersion"]
            )}
            {showVersionLine(
              "Software Slave 1",
              HPData.Controller["1"]["SoftwareModel"] +
                "." +
                HPData.Controller["1"]["SoftwareVersion"]
            )}
            {showVersionLine(
              "Software Slave 2",
              HPData.Controller["2"]["SoftwareModel"] +
                "." +
                HPData.Controller["2"]["SoftwareVersion"]
            )}
          </React.Fragment>
        );
      case "Nature Energy":
        return (
          <React.Fragment>
            {showVersionLine(
              "Software Master",
              HPData.Controller["1"]["SoftwareModel"] +
                "." +
                HPData.Controller["1"]["SoftwareVersion"]
            )}
            {showVersionLine(
              "Software Slave 1",
              HPData.Controller["2"]["SoftwareModel"] +
                "." +
                HPData.Controller["2"]["SoftwareVersion"]
            )}
            {showVersionLine(
              "Software Slave 2",
              HPData.Controller["3"]["SoftwareModel"] +
                "." +
                HPData.Controller["3"]["SoftwareVersion"]
            )}
            {showVersionLine(
              "Software Slave 3",
              HPData.Controller["4"]["SoftwareModel"] +
                "." +
                HPData.Controller["4"]["SoftwareVersion"]
            )}
            {showVersionLine(
              "Software Slave 5",
              HPData.Controller["5"]["SoftwareModel"] +
                "." +
                HPData.Controller["5"]["SoftwareVersion"]
            )}
          </React.Fragment>
        );

      default:
        return (
          <React.Fragment>
            {showVersionLine(
              "Software Master",
              HPData.Controller["1"]["SoftwareModel"] +
                "." +
                HPData.Controller["1"]["SoftwareVersion"]
            )}
          </React.Fragment>
        );
        
    }
  };

  const showVersionLine = (Titel: string, Value: string) => {
    return (
      <TableRow
        key={Titel}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell align="left">{Titel}</TableCell>
        <TableCell align="right">{Value}</TableCell>
      </TableRow>
    );
  };

  return {
    showupdateGateway,
    showDeleteLogsButton,
    vpnIP,
    lastUpdate,
    renderSoftwareVersionLines,
  };
}
